<template>
  <Popper ref="popper" v-bind="$attrs" :show="isOpen" style="z-index: 1">
    <div
      v-if="$slots.trigger"
      ref="trigger"
      :class="[{'active' : isOpen}]"
    >
      <slot name="trigger" />
    </div>
    <template #content="props">
      <div v-if="$slots.head" :class="[ {'py-4 px-7 w-[628px]' : !design.customDesignHead}, design.customDesignHead ]">
        <div class="relative">
          <div class="absolute top-0 right-0">
            <UiButton color="light" class="text-lg aspect-[1/1] leading-[0] w-[37px]" @click="close">
              x
            </UiButton>
          </div>
          <slot name="head" />
        </div>
      </div>
      <div v-if="$slots.default" :class="[ {'py-4 px-7 w-[628px]' : !design.customDesign}, design.customDesign ]">
        <slot v-bind="props" />
        <div
          v-if="$slots.foot"
          :class="[{'border-t border-neutral-200 grid grid-cols-2 gap-2 content-center py-4 px-5 text-left' : !design.customDesignFooter }, design.customDesignFooter ]"
        >
          <slot name="foot" />
        </div>
        <div v-if="$slots.triggerFooter" class="flex justify-end">
          <button @click="close">
            <slot name="triggerFooter" />
          </button>
        </div>
      </div>
    </template>
  </Popper>
</template>

<script setup lang="ts">
import Popper from 'vue3-popper'
const emit = defineEmits(['shown', 'hidden', 'update:isOpen'])

const design = defineProps({
  customDesign: {
    type: String,
    default: ''
  },
  customDesignHead: {
    type: String,
    default: ''
  },
  customDesignFooter: {
    type: String,
    default: ''
  }
})

const isOpen = ref()
const popper = ref()
const trigger = ref()

const eventListener = (event) => {
  if (event.type === 'mouseup' && !popper.value.$refs.popperNode.contains(event.target) && !trigger.value?.contains(event.target)) {
    hide()
  } else if (event.type === 'keydown' && event.key === 'Escape') {
    hide()
  }
}

const show = () => {
  isOpen.value = true
  document.addEventListener('mouseup', eventListener)
  document.addEventListener('keydown', eventListener)
  emit('shown')
  emit('update:isOpen', isOpen.value)
}

const hide = () => {
  isOpen.value = false
  document.removeEventListener('mouseup', eventListener)
  document.removeEventListener('keydown', eventListener)
  emit('hidden')
  emit('update:isOpen', isOpen.value)
}

const close = () => {
  const { $gtm } = useNuxtApp()
  $gtm.datepickerModule.sendDatepickerCloseEvent()
  hide()
}

const toggle = () => {
  isOpen.value ? hide() : show()
}

defineExpose({
  show,
  hide,
  toggle,
  isOpen
})

</script>

<style lang="scss">
:root {
  --popper-theme-background-color: #FFFFFF;
  --popper-theme-background-color-hover: #FFFFFF;
  --popper-theme-text-color: #000000;
  --popper-theme-border-color: rgb(228 228 228 / 1);
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 0.5rem;
  --popper-theme-padding: 0;
  --popper-theme-box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.popper #arrow, .popper #arrow::before{
  width: calc(15px - var(--popper-theme-border-width, 0px));
  height: calc(15px - var(--popper-theme-border-width, 0px));
}
.popper[data-popper-placement^="bottom"] > #arrow {
  top: -6px !important;
}
</style>
